<template>
  <div class="attendance-sign-edit">
    <div class="header">
      <div class="header-title">
        {{ title }}
      </div>
      <en-icon
        name="shanchu-danchuang"
        size="12px"
        @click.native="deleteData"
      >
      </en-icon>
    </div>
    <div class="content">
      <div class="center-contain">
        <en-icon
          class="sign-icon"
          size="29"
          name="kaoqin"
        ></en-icon>
      </div>
      <div class="bottom-contain">
        <div class="circle"></div>
        <div class="time">08:40</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "attendance-sign-edit",
  props: {
    menuData: {
      type: Object
    }
  },
  computed: {
    title() {
      return this.menuData?.name;
    }
  },
  methods: {
    deleteData() {
      this.$emit("deleteData");
    }
  }
};
</script>

<style lang="scss" scoped>

.attendance-sign-edit {
  height: 100%;
  position: relative;
  background-color: #fff;
  .header {
    width: 100%;
    line-height: 44px;
    border-bottom: 1px solid #e8ecf2;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    position: relative;

    .header-title {
      color: #1A1C1E;
      font-size:14px;
      font-weight: bold;
    }
    .en-icon {
      cursor: pointer;
      color: #a9b5c6;
      position: absolute;
      top: 15px;
      right: 20px;
    }
  }
  .content {
    height: calc(100% - 88px);
    .center-contain {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #3e90fe;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      .sign-icon {
        color: #ffffff;
      }
    }
    display: flex;
    align-items: center;
    justify-content: center;
    .bottom-contain {
      position: absolute;
      left: 20px;
      bottom: 18px;
      display: flex;
      align-items: center;
      .circle {
        width: 7px;
        height: 7px;
        background-color: #26c393;
        border-radius: 50%;
      }
      .time {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #636c78;
        padding-left: 6px;
      }
    }

  }
}

</style>
