var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "attendance-sign-edit" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("div", { staticClass: "header-title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _c("en-icon", {
          attrs: { name: "shanchu-danchuang", size: "12px" },
          nativeOn: {
            click: function ($event) {
              return _vm.deleteData.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "center-contain" },
        [
          _c("en-icon", {
            staticClass: "sign-icon",
            attrs: { size: "29", name: "kaoqin" },
          }),
        ],
        1
      ),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bottom-contain" }, [
      _c("div", { staticClass: "circle" }),
      _c("div", { staticClass: "time" }, [_vm._v("08:40")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }